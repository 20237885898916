body {
  overflow-y: scroll;
}

.c-nav {
  background: #333;
  color: #eee;
}

.c-nav-item {
  color: #eee;
  padding: 15px 25px;
}

.c-nav-item:hover {
  text-decoration: none !important;
  color: #eee;
  background: #444;
}

.c-nav-active {
  background: #222;
}

.c-nav-active:hover {
  background: #222;
}

